import './PhotoGalleries.css';

import mockData from '../MockData/photoGalleries.json'
import {useState} from "react";

function PhotoGalleries() {

    const [selectedGallery, setSelectedGallery] = useState(undefined);

    function PhotoGalleryCard({gallery}) {
        const image = '/' + gallery.photos[0].thumb
        return (
            <div className="photoGalleryColumn">
                <h3>{gallery.title}</h3>
                <h6>{gallery.author} - {gallery.date}</h6>
                <img alt="Photo" src={image} />
                <br/>
                Number of photos: {gallery.photos.length}
                <br/>
                <button onClick={() => setSelectedGallery(gallery)}>View gallery</button>
            </div>
        );
    }

    function PhotoGallery({gallery}) {
        return (
            <div>
                <h2>Gallery {gallery.id}</h2>
                <p>{gallery.title}</p>
                <p>{gallery.description}</p>
                <button onClick={() => setSelectedGallery(undefined)}>Back to all galleries</button>
                <br />
                <ul>
                    {gallery.photos.map((value, index) => {
                        const image = '/' + value.thumb
                        return <li key={index}>
                            <img alt="Photo" src={image} />
                        </li>
                    })}
                </ul>
            </div>
        );
    }

    function AllGalleries() {
        return (
            <div>
                <h2>Photo Galleries</h2>
                <div className="photoGalleriesRow">

                    {mockData.map((value, index) => {
                        return <div key={index}><PhotoGalleryCard gallery={value}/></div>
                    })}
                </div>
            </div>
        );
    }

    return (
        <div>
            {!selectedGallery ? <AllGalleries/> : <PhotoGallery gallery={selectedGallery} /> }
        </div>
    );
}

export default PhotoGalleries;
