import './Post.css';

import PostPhotos from "./PostPhotos";

function Post({post}) {
    return (
        <div>
            <h3>{post.title}</h3>
            <hr/>
            <h4>{post.author} - {post.date}</h4>
            <div>
                <div dangerouslySetInnerHTML={{ __html: post.text, }} />
            </div>
            <br />
            {post.photos.length > 0 &&  <PostPhotos post={post} />}
        </div>
    );
}

export default Post;
