import './SideBarLogin.css';
import Center from "../../RestyledComponents/Center";
import { get } from 'aws-amplify/api';
import React, { useEffect, useState } from 'react';

function SideBarLogin() {

    const [message, setMessage] = useState("(Amplify)");

    async function getMessage(e) {
  
        setMessage("...loading...");

        try {
          const restOperation = get({ 
            apiName: 'postresource',
            path: '/posts' 
          });
          const { body } = await restOperation.response;
          const str = await body.text();
          console.log('GET call succeeded: ', str);
          setMessage(str);
        } catch (e) {
          console.log('GET call failed: ', JSON.parse(e.response.body));
          setMessage("error!");
        }
      };

    return (
        <div>
            <Center>
                Logged in as: <b>dfdgfg234</b>
                <br/><br/>
                <button onClick={() => getMessage()}>Logout</button>
                <br/><br/>
                <span>{message}</span>
            </Center>
        </div>
    );
}

export default SideBarLogin;
