import './Footer.css';

function Footer() {
    return (
        <div>
            <p>This is the footer with a link: <a href="#">color pistachio</a></p>
        </div>
    );
}

export default Footer;
