import './SideBarEvents.css';

import mockData from '../../MockData/events.json'
import SideBarEvent from "./SideBarEvent";

function SideBarEvents() {
    return (
        <div>
            <h4>Upcoming events</h4>
            <ul>
                {mockData.map((value, index) => {
                    return <li key={index}><SideBarEvent event={value}/></li>
                })}
            </ul>
        </div>
    );
}

export default SideBarEvents;
