import './About.css';

function About() {
    return (
        <div>
            <h2>About page</h2>
            <p>
                dsfdsfds gdf gdf
            </p>
        </div>
    );
}

export default About;
