import './Events.css';

import mockData from '../MockData/events.json'
import Event from "./Event";

function Events() {
    return (
        <div>
            <h2>Events</h2>

            {mockData.map((value, index) => {
                return <div key={index}><Event event={value}/></div>
            })}

        </div>
    );
}

export default Events;
