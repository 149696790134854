import './App.css';
import Header from './Header/Header'
import Footer from './Footer/Footer'
import NavBar from './NavBar/NavBar'
import {BrowserRouter} from "react-router-dom";

function App() {
    return (
        <BrowserRouter>
            <Header/>
            <NavBar/>
            <Footer/>
        </BrowserRouter>
    );
}

export default App;