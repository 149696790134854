import './Header.css';
import logo from '../euphonium_logo.jpg';

function Header() {
    return (
        <header className="App-header">
            <div>
                <img className="headerLogo" src={logo} />
                <h1>Brass Blog</h1>
                <h4>The Brass Blog for everything Brass related</h4>
            </div>
        </header>
    );
}

export default Header;
