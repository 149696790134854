import './NavBar.css';
import React, { useState } from "react";
import MenuIcon from '../bar_menu_48.png';
import {NavLink, Route, Routes, useLocation} from "react-router-dom";
import PhotoGalleries from "../PhotoGalleries/PhotoGalleries";
import About from "../About/About";
import Posts from "../Posts/Posts";
import Events from "../Events/Events";
import SideBar from "../SideBar/SideBar";
import History from "../History/History";

/*
   Had to use this trick to get the selected tab highlighted:

   https://medium.com/how-to-react/add-an-active-classname-to-the-link-using-react-router-b7c350473916
*/

function NavBar() {

    const [isResponsiveMenu, setResponsiveMenu] = useState(false);
    const [selectedMenuItem, setSelectedMenuItem] = useState("Posts");

    //assigning location variable
    const location = useLocation();

    //destructuring pathname from location
    const {pathname} = location;

    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/");

    function toggleMenu() {
        setResponsiveMenu(!isResponsiveMenu);
    }

    function collapseMenu(label) {
        setResponsiveMenu(false);
        setSelectedMenuItem(label);
    }

    return (
        <div>
            <div className={isResponsiveMenu ? "navBar responsive" : "navBar"}>

                {!isResponsiveMenu && <div id="menuNavLink" className="navLink navLinkActive" onClick={toggleMenu}>
                    <img className="menuIcon" src={MenuIcon} />
                    {selectedMenuItem}
                </div>}

                <NavLink className="navLink" onClick={() => { collapseMenu('Posts'); }}
                         activeclassname={splitLocation[1] === "" ? "navLinkActive" : ""}
                         to="/">Posts</NavLink>

                <NavLink className="navLink" onClick={() => { collapseMenu('Events'); }}
                         activeclassname={splitLocation[1] === "events" ? "navLinkActive" : ""}
                         to="/events">Events</NavLink>

                <NavLink className="navLink" onClick={() => { collapseMenu('Photos'); }}
                         activeclassname={splitLocation[1] === "photoGalleries" ? "navLinkActive" : ""}
                         to="/photoGalleries">Photos</NavLink>

                <NavLink className="navLink" onClick={() => { collapseMenu('Historie'); }}
                         activeclassname={splitLocation[1] === "history" ? "navLinkActive" : ""}
                         to="/history">Historie</NavLink>

                <NavLink className="navLink" onClick={() => { collapseMenu('About'); }}
                         activeclassname={splitLocation[1] === "about" ? "navLinkActive" : ""}
                         to="/about">About</NavLink>

            </div>

            <div className="contentWrapper">

                <div className="sideBar">
                    <SideBar/>
                </div>

                <div className="mainContent">

                    <Routes>
                        <Route exact path="/photoGalleries" element={<PhotoGalleries/>}/>                             
                        <Route exact path="/events" element={<Events/>}/>                             
                        <Route exact path="/about" element={<About/>}/>                             
                        <Route exact path="/history" element={<History/>}/>                             
                        <Route exact path="/" element={<Posts/>}/>                                             
                    </Routes>
                </div>

            </div>



        </div>
    );
}

export default NavBar;
