import './Posts.css';

import Center from "../RestyledComponents/Center";
import Post from './Post'

import mockData from '../MockData/posts.json'

function Posts() {
    return (
        <div>
            {mockData.map((value, index) => {
                return <div key={index}><Post post={value}/></div>
            })}

            <div>
                <Center>
                    <button text="Vis eldre innlegg" icon="chevron_down" />
                </Center>
            </div>
        </div>
    );
}

export default Posts;
