import './Events.css';

function Event({event}) {
    return (
        <div>
            <h3>{event.title}</h3>
            <h5>{event.location} - {event.date}</h5>
            <p>
                {event.description}
            </p>
        </div>
    );
}

export default Event;
