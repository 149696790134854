import './PostPhotos.css';

function PostPhotos({post}) {
    return (
        <div className="postPhotosThumbs">
            {post.photos.map((value, index) => {
                const image = '/' + value.thumb
                return <div className="postPhotosThumb" key={index}>
                    <img alt="Photo" src={image} />
                </div>
            })}
        </div>
    );
}

export default PostPhotos;
