import './SideBarEvent.css';

function SideBarEvent({event}) {
    return (
        <div>
            {event.title} - {event.location} - {event.date}
        </div>
    );
}

export default SideBarEvent;
