import './SideBar.css';
import SideBarEvents from "./SideBarEvents/SideBarEvents";
import SideBarLogin from "./SideBarLogin/SideBarLogin";

function SideBar() {
    return (
        <div>
            <div className="sideBarWidget">
                <SideBarEvents/>
            </div>
            <div className="sideBarWidget">
                <SideBarLogin/>
            </div>
        </div>
    );
}

export default SideBar;
